import React from 'react';
import { Col, Row } from "react-bootstrap";

// import guitar_768 from './resources/guitar_768.png';
// import guitar_1536 from './resources/guitar_1536.png';
// import guitar_2304 from './resources/guitar_2304.png';
// import guitar_4608 from './resources/guitar_4608.png';

import stage_768 from './resources/stage_768.jpg';
import stage_1536 from './resources/stage_1536.jpg';
import stage_full from './resources/stage_full.jpg';
import stage_full_full from './resources/stage_full_full.jpg';

import './css/hero.css';

export class Kaas extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (e) => {
    let opacity = 1;
    const checkpoint = window.innerHeight;

    const currentScroll = window.pageYOffset;
    if (currentScroll <= checkpoint) {
      opacity = 1 - currentScroll / (checkpoint*0.7);
    } else {
      opacity = 0;
    }
    document.querySelector(".hero").style.opacity = opacity;
  }

  handleLoad = (e) => {
    const fixHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    
    fixHeight();

   // window.addEventListener('resize', fixHeight);
  }

  render() {
    return (
      <Row onLoad={this.handleLoad} className="justify-content-center hero" fluid="true">
{/*         <img alt="guitar_background" srcset={`
          ${guitar_1536} 1536w,  
          ${guitar_2304} 2304w,  
          ${guitar_4608} 4608w`}
        /> */}
        <img alt="guitar_background" srcSet={`
          ${stage_768} 768w,
          ${stage_1536} 1536w,  
          ${stage_full} 2140w,
          ${stage_full_full} 3635w`}
        />
        <Slogan />
      </Row>
    );
  }
}

export class Slogan extends React.Component {
  render() {
    return (
      <div className="slogan">
        <Col xs={1} lg={1}>
        </Col>
        <Col xs={11} md={5} className="slogan-textblock mt-auto mb-8 pr-3">
          <div className="slogan-header">
            <h1>
              <div className="pulse">U, Versterkt</div>
              <em>.</em>
            </h1>
          </div>
          <div>
            <h2>
              Geluidstechniek en management voor muziekevenementen, bands en venues.
            </h2>
          </div>
        </Col>
      </div>
    )
  }
}