import React from 'react';

import { scrollThresholdReached, stopAnimation, animate } from './fade.js';

import './css/fadein.css';
import './css/contact.css'

class Social extends React.Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleLoad = (e) => {
    if (scrollThresholdReached(this.element.current)) {
      stopAnimation(this.element.current, "fadeInRight"+this.props.order);
    }
  }

  handleScroll = (e) => {
    animate(this.element.current, "fadeInRight"+this.props.order, 1.0);
  }

  render() {
    return (
      <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
          <i onScroll={this.handleScroll} onLoad={this.handleLoad} ref={this.element} className={"hidden-until-animation fab " + this.props.icon} aria-hidden="true" />
      </a>
    )
  }
}

export class Contact extends React.Component {

  render() {
    return (
      <ul className="contact">
        <li>
          <Social icon="fa-facebook-f" order="First" link="https://www.facebook.com/risseeuwsoundandsupport/" />
          <Social icon="fa-instagram" order="Second" link="https://www.instagram.com/bjriss/" />
          <Social icon="fa-linkedin" order="Third" link="https://nl.linkedin.com/in/bartjan-risseeuw-a05bb6128" />
        </li>
        { this.props.children }
      </ul>
    )
  }
}