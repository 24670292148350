import React from 'react'
import { scrollThresholdReached, stopAnimation, animateVisible } from './fade.js';

import './css/fadein.css';
import './css/underbar.css'

export class Underbar extends React.Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleLoad = (e) => {
    if (scrollThresholdReached(this.element.current)) {
      stopAnimation(this.element.current, "underbar");
    }
  }

  handleScroll = (e) => {
    animateVisible(this.element.current, "underbar", 1.0);
  }

  render() {
    return (
      <div ref={this.element} className="underbar">
        {this.props.children}
      </div>
    )
  }
}