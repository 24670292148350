export function scrollThresholdReached(element, threshold)  {
  var elemBottom = element.getBoundingClientRect().bottom;

  return elemBottom <= window.innerHeight*threshold;
}

export function stopAnimation(element, klass) {
  element.classList.remove("hidden-until-animation");
  element.classList.remove(klass);
}

export function animate(element, klass, threshold) {
  animateClass("hidden-until-animation", element, klass, threshold);
}

export function animateVisible(element, klass, threshold) {
  animateClass("visible-before-animation", element, klass, threshold);
}

function animateClass(removeClass, element, klass, threshold) {
  if (scrollThresholdReached(element, threshold) && element.classList.contains(removeClass)) {
    element.classList.add(klass);
    element.classList.remove(removeClass)
    void element.offsetWidth;
  }
}