import React from 'react';
import { Navbar, Nav, Col, Container, Row, Button, Popover, OverlayTrigger } from "react-bootstrap";
import './css/nav.css'

import eventBus from "./eventbus.js";

class ContactItem extends React.Component {
  render() {
    const icon = this.props.icon;

    return (
      <Row className="mb-1">
          <Col xs={1}>
            {icon}
          </Col>
          <Col xs={10} className="pl-2">
            {this.props.children}
          </Col>
      </Row>
    )
  }
}

function telefoon_icon() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-phone" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M11 1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
      <path fill-rule="evenodd" d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
    </svg>
  );
}

function mail_icon() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-envelope" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
    </svg>
  );
}

function whatsapp_icon() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chat-dots" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
      <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
    </svg>
  );
}

class SupportButton extends React.Component {
  render() {
    const style = "outline-light";

    //<ContactItem icon={whatsapp_icon()}>WhatsApp</ContactItem>

    const popover = (
      <Popover id="popover-positioned-bottom">
        <Popover.Content>
          <ContactItem icon={telefoon_icon()}>+31 (0) 6 30 77 82 06</ContactItem>
          <ContactItem icon={mail_icon()}>info@soundandsupport.com</ContactItem>
          
        </Popover.Content>
      </Popover>
    )

    return (
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
        <Button className="ml-xl-auto ml-auto mr-2 order-1 order-xl-3" variant={style}>
          <b>Contact</b>
        </Button>
      </OverlayTrigger>
    )
  }
}

class NavUnit extends React.Component {
  constructor(props) {
    super(props);

    this.dispatchClick = () => {
      eventBus.dispatch("closeWid" , {});
      eventBus.dispatch("nav"+ this.props.eventbusTag , {});
      this.props.close();
    };
  }

  render() {
    return (
      <Nav.Item className="mx-4">
        <b>
          <Nav.Link onClick={this.dispatchClick} href={this.props.href}>{this.props.children}</Nav.Link>
        </b>
      </Nav.Item>
    )
  }
}

class Brand extends React.Component {
  render() {
    return (
      <Navbar.Brand className="order-4 order-xl-1 pt-1 pt-lg-3 pt-xl-0 text-center text-xl-right col-12 col-xl-3">
        <b>SOUND<em>&</em>SUPPORT</b>
      </Navbar.Brand>
    )
  }
}

export class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nav_expanded: false
    };
  }

  render() {
    const items = [
      { 
        text : "Geluidstechnicus",
        tag: "geluidstechnicus"
      },
      {
        text: "Audioverhuur",
        tag: "audioverhuur"
      },
      {
        text: "Theater",
        tag: "theater"
      },
/*       {
        text: "Socials",
        tag: "socials"
      } */
    ];

    const closeNav = () => {
      this.setState({ nav_expanded: false });
    }

    const setNavExpanded = (expanded) => {
      this.setState({ nav_expanded: expanded });
    }

    return (
      <Row>
        <Navbar onToggle={setNavExpanded} expanded={this.state.nav_expanded} variant="dark" className="pt-1" fixed="top" expand="xl">
          <Container className="p-xl-0">
            <Brand />
            <Navbar.Toggle className="order-xl-2 order-2" label="Menu" aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="col-12 col-xl-9 order-xl-2 order-3 mt-xl-0 mt-3 pr-0" id="basic-navbar-nav">
              <Nav ref={e => this.navbar = e} onSelect={closeNav} className="mr-xl-2 pr-0 text-right" activeKey="/home">
                {items.map((item, index) => (<NavUnit close={closeNav} eventbusTag={item.tag} key={"nav-" + index}>{item.text}</NavUnit>))}
              </Nav>
            </Navbar.Collapse>
            <SupportButton />
          </Container>
        </Navbar>
      </Row>
    );
  }
}
