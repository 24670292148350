import React from 'react';
import ReactDOM from 'react-dom';

import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';

import { Navigation } from './nav.js';
import { Kaas } from './kaas.js';
import { Wid } from './wid.js';
import { OverMij } from './overmij.js';
import { Footer } from './footer.js'

import './css/bootstrap.min.css';
import './css/base.css';
import './css/nav.css';

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

    render() {
        const script = [
    //        "https://unpkg.com/react/umd/react.production.min.js",
    //        "https://unpkg.com/react-dom/umd/react-dom.production.min.js",
    //        "https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js",
              "https://kit.fontawesome.com/cb44a5a798.js",
              "https://cdn.jsdelivr.net/npm/pace-js@latest/pace.min.js"
        ];

        const scriptTags = script.map((url, index) => (<script key={"script-" + index} src={url} crossorigin></script>));

        const styleSheet = [
//            {
//                url: "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css",
//                hash: "sha256-aAr2Zpq8MZ+YA/D6JtRD3xtrwpEz2IqOS+pWD/7XKIw= sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk sha512-rO2SXEKBSICa/AfyhEK5ZqWFCOok1rcgPYfGOqtX35OyiraBg6Xa4NnBJwXgpIRoXeWjcAmcQniMhp22htDc6g=="
//            }
        ];

        const linkTags = styleSheet.map((tag, index) => (<link key={"link-" + index} rel="stylesheet" href={tag.url} integrity={tag.hash} crossorigin="anonymous" />));

        return (
            <Container id="bodybox" fluid>
                <Helmet>
                    <title>
                        Sound & Support
                    </title>
                    {scriptTags}
                    {linkTags}
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap" />
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" />
		            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/pace-js@latest/pace-theme-default.min.css" />
                </Helmet>
                    <Navigation />
                    <Kaas />
                    <Wid />
                    <OverMij />
                    <Footer />
            </Container>
        );
    }
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
)
