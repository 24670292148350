import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { InstaFeed } from './insta.js'
import { Contact } from './contact.js';
import { Underbar } from './underbar.js';

import './css/overmij.css';

import bj from './resources/competencies/contact.jpg'
import bartjan from './resources/competencies/bartjan.jpg';

class OverMijCard extends React.Component {
  render() {
    return (
      <Row className="over-mij">
        <Row className="vertical-wrapper">
          <Col xl={3} lg={5} className="image-wrapper d-lg-block d-none px-0 text-right">
            <img src={bj} alt="Bartjan1" />
          </Col>
          <Col xl={5} lg={7} md={12} sm={12} className="over">
            <Underbar>
              <h3>Bartjan</h3>
              <h2>Risseeuw<em>.</em></h2>
            </Underbar>
            <p>
            Als freelancer en verhuurpartner in de audio-industrie streef ik het verschil te maken in het veranderende audiolandschap. De vlucht in technische details lijkt soms in de weg te liggen van muzikaliteit. Een vastberaden glimlach en de wil om van elke show een betere te maken, typeert mijn aanpak.<br /><br />
          En waarom?<br />
          <em>Because, I care.</em>
            </p>
            <Contact />
          </Col>
          <Col lg={4} md={0} className="image-wrapper d-xl-block d-none px-0 text-right">
            <img src={bartjan} alt="Bartjan2" />
          </Col>
        </Row>
        <Col lg={1} md={0} />
      </Row>
    );
  }
}

export class OverMij extends React.Component {
  render() {
    return (
      <div>
        <OverMijCard />
        <Col lg={12}>
        <InstaFeed />
        </Col>
      </div>
    )
  }
}