import React from 'react';
// import InstagramEmbed from 'react-instagram-embed';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Row, Col } from 'react-bootstrap';

import './css/insta.css'
import './css/fadein.css'

import { scrollThresholdReached, stopAnimation, animate } from './fade.js';

import one from './resources/instamock/9.jpg';
import two from './resources/instamock/8.jpg';
import three from './resources/instamock/7.jpg';
import four from './resources/instamock/6.jpg';
import five from './resources/instamock/5.jpg';
import six from './resources/instamock/4.jpg';
import seven from './resources/instamock/3.jpg';
import eight from './resources/instamock/2.jpg';
import nine from './resources/instamock/1.jpg';

const insta =
  [
    {
      img: one,
      body: "I had a great time Erik! Enjoy your set of pristine audio gear, for a long time to come!",
      link: "https://www.instagram.com/p/CFaWui3A8Sk/"
    },
    {
      img: two,
      body: "...Shakin' the Nijverheid. Rave till u drop... #customPA #fir #kblsystems #Rave #industrial #1.5m #makesomenoize",
      link: "https://www.instagram.com/p/CFDEe1gALbB/"
    },
    {
      img: three,
      body: "Groet uit Rotterdam. Liefs, New Jazz Orchestra #koper #blazers #dlive #c3500 #sm57 #km184 #uitverkocht!",
      link: "https://www.instagram.com/p/CE9u-iUAiOj/"
    },
    {
      img: four,
      body: "~De Baron -vs- Westergas~. Drie keer raden wie er gewonnen heeft... Is er gedanst? Dat zeggen we lekker niet. #unxpctd #galm #toeters #functionone #allen&heath #SQ5",
      link: "https://www.instagram.com/p/CEtSIIMg7OR/"
    },
    {
      img: five,
      body: "Kickin' it in Ronda tonight. People who are about to join, hold on to your socks, Peter Beets is here. #backontheroad #gerswin #jazz #steinwayandsons #fenderrhodes #sdten #tlm103 #kblsystems DI #walktheline",
      link: "https://www.instagram.com/p/CD9Q5qPAyGS/"
    },
    {
      img: six,
      body: "Thanks Guys, I had a blast yesterday! Let's do this again one day 😃 #woodenbuilttheatre #bongerd #custombuilt #meyerupa #dlive #ksm9 #gitaren #dialect",
      link: "https://www.instagram.com/p/CDbJApNgD8N/"
    },
    {
      img: seven,
      body: "Fun in the sun, with my friends. Good to be going again! #meyerupa #ksm9 #M32 #relabvsrs24",
      link: "https://www.instagram.com/p/CCnRgOugPde/"
    },
    {
      img: eight,
      body: "Rescued these guys from a couple Marantz monoblocks. Such a shame they sound a bit tired, they look amazin'! #elna #foraudio #capacitor #marantz #audioenhancement #soldeerbout",
      link: "https://www.instagram.com/p/B_h2LQaAGBL/"
    },
    {
      img: nine,
      body: "Doing rainy day stuff. No gig's planned for today, figured I could do something I was set out to do for a while.",
      link: "https://www.instagram.com/p/B9ZOni9AXST/"
    }
  ];

class InstaCard extends React.Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleLoad = (e) => {
    if (scrollThresholdReached(this.element.current, 1.3)) {
      stopAnimation(this.element.current, "fadeInBottom");
    }
  }

  handleScroll = (e) => {
    animate(this.element.current, "fadeInBottom", 1.3);
  }
  
  render() {
    return (
      <Col lg={4} md={12} className="px-0 px-md-5 mx-0">
        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
          <div ref={this.element} onLoad={this.handleLoad} onScroll={this.handleScroll} className="hidden-until-animation card-wrapper">
            <LazyLoadImage src={this.props.img} wrapperClassName="img-fluid" effect="blur" alt="" />
            <p className="description">{this.props.body}</p>
          </div>
        </a>
      </Col>
    )
  }
}

export class InstaRow extends React.Component {
  render() {
    return (
      <Row className="insta">
        <InstaCard img={this.props.left.img} body={this.props.left.body} link={this.props.left.link} />
        <InstaCard img={this.props.mid.img} body={this.props.mid.body} link={this.props.mid.link}/>
        <InstaCard img={this.props.right.img} body={this.props.right.body} link={this.props.right.link} />
      </Row>
    )
  }
}

export class InstaFeed extends React.Component {
  render() {

      /*         <InstagramEmbed
          url='https://instagram.com/bjriss/'
          clientAccessToken='1336944483340238|73b0d3ef6fd30133910d1f369640aa09'
          maxWidth={320}
          hideCaption={false}
          containerTagName='div'
          protocol=''
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
        /> */

    return (
      <Row>
        <Col lg={12}>
          <InstaRow left={insta[0]} mid={insta[1]} right={insta[2]} />
          <InstaRow left={insta[3]} mid={insta[4]} right={insta[5]} />
        </Col>
        <Col lg={12} className="d-none d-md-block">
          <InstaRow left={insta[6]} mid={insta[7]} right={insta[8]} />
        </Col>
      </Row>
    )
  }
}
