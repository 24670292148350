import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Contact } from './contact.js';

import './css/footer.css';
import './css/contact.css'

export class Footer extends React.Component {
  render() {
    return (
      <Row className="footer justify-content-center">
        <Col xl={2} md={0} />
        <Col xl={3} md={6}>
          <ul className="pl-0">
            <li>Risseeuw Sound<em>&</em>Support</li>
            <li>Hogenackerstraat 14</li>
            <li>5066XS Moergestel</li>
            <li>KvK: 63466082</li>
          </ul>
        </Col>
        <Col xl={3} md={6}>
          <Contact>
            <li>
              <i className="fa fa-mobile" aria-hidden="true" />
              <span>&nbsp; +31 (0) 6 30 77 82 06</span>
            </li>
            <li>
              <i className="fa fa-envelope" aria-hidden="true" />
              <span>&nbsp; info@soundandsupport.com</span>
            </li>
          </Contact>
        </Col>
        <Col xl={2} lg={12} >
          <span className="mt-md-3 dev">Webdevelopment met ♥<br />door <a target="_blank" rel="noopener noreferrer" href="https://github.com/dmry">Daniël Emmery</a></span><br />
          <span className="mt-md-3 dev"><a href="/privacy_policy.txt">Privacy policy</a></span>
        </Col>
        <Col xl={2} md={0} />
      </Row>
    )
  }
}
