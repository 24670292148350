import './css/wid.css';
import './css/fadein.css';
import { scrollThresholdReached, stopAnimation, animate } from './fade.js';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import techniek from './resources/competencies/techniek.jpg';
import theater from './resources/competencies/theater2.jpg';
import stage from './resources/competencies/stage_vert.jpeg'

//import willemeen from './resources/references/willemeen_white.png'
import loburg from './resources/references/loburg_white.png'
//import argoband from './resources/references/argoband_white.png'
//import bevrijdingsfestival from './resources/references/bevrijdingsfestival_white.png'
import debaron from './resources/references/debaron_white.png'

import eventBus from "./eventbus.js";

function getOffsetTop(element) {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

class Partner extends React.Component {
    render() {
      return (
        <Col xs={6} className="partner">
          <a target="_blank" rel="noopener noreferrer" href={this.props.link}><LazyLoadImage key={"partner_link_" + this.props.index} alt="partner_link" src={this.props.logo} /></a>
        </Col>
      );
    }
}

export class WidItem extends React.Component {

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })

    eventBus.on("nav"+this.props.eventbusTag, (data) => {
        this.handleClick()
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)

    eventBus.remove("nav"+this.props.eventbusTag);
  }

  handleLoad = (e) => {
    var element = document.querySelector("#wid-list-item-" + this.props.index)

    if (scrollThresholdReached(element, 1.5)) {
      stopAnimation(element, "fadeInBottom");
    }

    this.handleScroll(e);
  }

  handleScroll = (e) => {
    var element = document.querySelector("#wid-list-item-" + this.props.index)

    animate(element, "fadeInBottom", 1.5);
  }

  handleClick = (e) => {
      var elements = document.getElementsByClassName('wid-card');

      var thisElement = document.querySelector("#wid-list-item-image-" + this.props.index)

      if (!elements) {
        return;
      }

      for (var i = 0; i < elements.length; i++) {
        var el = elements[i].children[0];

        if (el !== thisElement) {
          el.parentElement.classList.toggle("inactive");
        }
      }

      thisElement.parentElement.classList.toggle("active");

      let parentWrap = thisElement.parentElement.parentElement
      parentWrap.classList.toggle("active");

      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

      var offset = 100; // sticky nav height

      if (vw < 768) {
        offset = 0.2*vh;
      } else if (vw < 1200) {
        offset = 0.13*vh;
      }

      window.scroll({ top: (getOffsetTop(thisElement) - offset), left: 0, behavior: 'smooth' });

      this.props.canvasCallback();
  }

  render() {
    return (
      <Col onLoad={this.handleLoad} onClick={this.handleClick} xl={4} sm={12} className={"mx-0 px-2 px-md-4 mr-auto wid-card " + this.props.position + " hidden-until-animation"} id={"wid-list-item-" + this.props.index}>
        <div className="card-wrapper" id={"wid-list-item-image-" + this.props.index}>
          <img src={this.props.cover} alt="competentie_cover" />
          <h1>{this.props.titel}<em>.</em></h1>
          <div className="body">
            <i className="fa fa-times-circle-o" aria-hidden="true"></i>
            <h2>Wat kan ik voor u betekenen?</h2>
            <p>{this.props.body}</p>
            <Row>
              {this.props.partners.map((item, index) => {return (<Partner key={item.key} logo={item.logo} link={item.link} index={index} />)})}
            </Row>
          </div>
        </div>
      </Col>
    )
  }
}

export class WidItemList extends React.Component {
  render() {
    const listitems = [
      {
        titel: "Geluidstechnicus",
        tag: "geluidstechnicus",
        body: (<span>Als geluidstechnicus staat het mixen van audio op één. Elke sound die je maakt is content-driven en zou niet beperkt mogen worden door de techniek en onze omgeving. Ik zorg ervoor dat er geen enkele twijfel overblijft en de muziek ook de aandacht krijgt die ze verdient. Ongeacht de situatie.</span>),
        img: techniek,
        partners: [
            // TODO: peter beets
            {
                key: "loburg",
                logo: loburg,
                link: "https://live.loburg.com/"
            },
            {
                key: "debaron",
                logo: debaron,
                link: "http://de-baron.com"
            },
        ]
      },
      {
        titel: "Audioverhuur",
        tag: "audioverhuur",
        body: (<span>Een festival staat of valt bij zijn voorzieningen, waarin livemuziek vaak centraal staat. Voor elk evenement is er dan ook een passende oplossing te bedenken. Ik zoek graag met jou naar het beste resultaat binnen het budget. Niets is te gek.</span>),
        img: stage,
        partners: [

        ]
      },
      {
        titel: "Theater",
        tag: "theater",
        body: (<span>Het inrichten van geluidssystemen vergt veel toewijding, kennis en vasthoudendheid. Elke venue vraagt om een oplossing op maat. Gegevens zoals Frequentie- & Phaseresponse gaan vaak gepaard met een hoop technische termen. Maar sluiten ze aan bij de verwachtingen? </span>),
        img: theater,
        partners: [
        ]
      }
    ]

    return listitems.map((item, index) => {
      var position = "left";

      switch(index) {
        case 1:
          position = "middle";
          break;
        case 2:
          position = "right";
          break;
        default:
          //left
          break;
      } 

      return (
        <WidItem eventbusTag={item.tag} index={index} canvasCallback={this.props.canvasCallback} position={position} key={ "wid" + index } titel={item.titel} cover={item.img} body={item.body} partners={item.partners}/>
      )
    });
  }
}

//<h1>Alles in huis om uw evenement tot een succes te maken.</h1>
export class WidHeader extends React.Component {
  render() {
    return (
      <Row className="w-100 m-0 d-md-block">
        <Col md={3} />
        <Col sm={12} md={6} className="mx-auto wid-header text-center">
        </Col>
        <Col md={3} />
      </Row>
    );
  }
}


export class Wid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canvasActive: false
    };
  }

  componentDidMount() {
    eventBus.on("closeWid", (data) => {
        this.handleClick()
      }
    );
  }

  componentWillUnmount() {
    eventBus.remove("closeWid");
  }


  handleClick = (e) => {
    if (this.state.canvasActive) {
      var elements = document.getElementsByClassName('wid-card');

      var topElement = document.getElementsByClassName('wid-card-row');

      if (topElement[0]) {
        topElement[0].classList.remove("active");
      }

      for (var i = 0; i < elements.length; i++) {
          var el = elements[i].children[0];
          el.parentElement.classList.remove("inactive");
          el.parentElement.classList.remove("active");

          // Lelijk, maar zorgt dat deze niet ook speelt zodra de kaart gesloten wordt.
          el.parentElement.classList.remove("fadeInBottom");
      };

      this.setState({ canvasActive: false })
    }
  }

    render() {
        return (
            <Row onClick={this.handleClick} className="wid">
              <WidHeader />
              <Col md={1} className="ml-auto" />
              <Col md={10} className="mx-auto">
                <Row className="wid-card-row">
                  <WidItemList canvasCallback={() => this.setState({ canvasActive: true })} />
                </Row>
              </Col>
              <Col md={1} className="mr-auto" />
            </Row>
        );
    }
}